/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { setAffiliateListIdCookie } from "@lib/shared/cookies";
import { Image, ListingItem, ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";
import { ServerResponse } from "http";
import { DomainInfo } from "./domainInfo";
import { getAccessToken } from "@lib/shared/cookies";

interface ComparisonListingResponse {
    data: ComparisonListing | null;
    success: boolean;
}

interface ListingResponse {
    success: boolean;
    data: Listing | null;
}
interface ListingV3Response {
    success: boolean;
    data: ListingV3 | null;
}

export interface Listing {
    id: number;
    name: string;
    slug: string;
    description: string;
    pageTitle: null;
    domainInfo: DomainInfo;
    offers: ListingItem[];
    image: Image | null;
    features: string[];
    hiddenAttributes: { color: string };
}
export interface ListingV3 {
    id: number;
    name: string;
    slug: string;
    description: string;
    pageTitle: null;

    offers: ListingItem[];
    image: Image | null;
    features: string[];
    icon: Image | null;
    translations: string[];
    attributes: string[];
    hiddenAttributes: { color: string };
    modelType: string;
}

export interface ComparisonListing {
    domainInfo: DomainInfo;
    listingsInfo: {
        id: number;
        name: string;
        features: string[];
        pageTitle: string | null;
        slug: string;
        image: Image | null;
        description: string;
        attributes: [
            {
                key: string;
            },
        ];
    };
    offers: ListingItem[];
}

export const getComparisonListing = async ({
    req,
    res,
}: {
    req: ServerRequest;
    res: ServerResponse;
}): Promise<{ data: ComparisonListing | null; error: Error | null }> => {
    const accessToken = req.accessToken;

    const config: AxiosRequestConfig = {
        method: "get",
        url: "/api/web/v2/listings/comparison",
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req.locale ?? "en",
        },
    };

    const { data: comparisonListingResponse, error } =
        await apiHandler<ComparisonListingResponse>(() => HTTP.server(config));

    if (comparisonListingResponse && !error)
        setAffiliateListIdCookie(
            comparisonListingResponse?.data?.listingsInfo.id as number,
            req,
            res,
        );

    return {
        data: comparisonListingResponse?.data ?? null,
        error,
    };
};

export const getListingBySlug = async ({
    req,
    res,
    slug,
}: {
    req: ServerRequest;
    res: ServerResponse;
    slug: string;
}): Promise<{ data: Listing | null; error: Error | null }> => {
    const accessToken = req.accessToken;

    const config: AxiosRequestConfig = {
        method: "get",
        url: `/api/web/v2/listings/${slug}`,
        headers: {
            "X-DOMAIN-NAME": `${req.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req.locale ?? "en",
        },
    };

    const { data: listingResponse, error } = await apiHandler<ListingResponse>(
        () => HTTP.server(config),
    );

    if (listingResponse?.data?.id && !error) {
        setAffiliateListIdCookie(listingResponse?.data?.id, req, res);
    }

    return {
        data: listingResponse?.data ?? null,
        error,
    };
};
export const getListingBySlugV3 = async ({
    req,
    res,
    slug,
    skipCategory = true,
}: {
    req?: ServerRequest;
    res?: ServerResponse;
    slug: string;
    skipCategory?: boolean;
}): Promise<{ data: ListingV3 | null; error: Error | null }> => {
    const accessToken = req?.accessToken ?? getAccessToken();

    const config: AxiosRequestConfig = {
        method: "get",
        url: req
            ? `/api/web/v3/listings/${slug}${
                  skipCategory ? "?skipCategory=true" : ""
              }`
            : `/api/listing-v3/${slug}${
                  skipCategory ? "?skipCategory=true" : ""
              }`,
        headers: {
            "X-DOMAIN-NAME": `${req?.headers?.host ?? ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req?.locale ?? "en",
        },
    };

    const { data: listingResponse, error } =
        await apiHandler<ListingV3Response>(() => HTTP.server(config));

    if (listingResponse?.data?.id && !error) {
        setAffiliateListIdCookie(listingResponse?.data?.id, req, res);
    }

    return {
        data: listingResponse?.data ?? null,
        error,
    };
};
